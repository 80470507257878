'use strict'

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles'
import Link from 'next/link';
import { url, LinkType } from '../paths';
import { APP_NAME } from '../config';
import Bullets from '../components/landing/bullets';
import { Theme } from '@material-ui/core';
import Head from 'next/head';
import ReactGA from 'react-ga';
import { PUBLIC_ASSETS_CDN } from '../config';
import dynamic from 'next/dynamic';
import VisibilitySensor from 'react-visibility-sensor';

const SampleCollection = dynamic(() => import('../components/apolloshopify/collections/samples'),
  { loading: () => <p>...LOADING...</p> }
)
const ProductTypes = dynamic(() => import('../components/landing/producttypes'), { loading: () => <p>...LOADING...</p> });

// import ProductTypes from '../components/landing/producttypes';

//mainHeader = 'Made by You'
//const mainHeader = `Create art now, love it for life.`;
//const mainHeader = `Art, Like You`;
// const mainHeader = `You are the future of art`
// const mainHeader = `Art, by you.`
//const subHeader = `We use expertise and powerful super computers to craft your meaningful photos into beautiful artworks based, precisely, on your personal tastes and preferences. They're beautiful, personal, and you have creative control every step of the way.`;
// `Turn photos into world-class artwork.`

const _Labels = {
  mainHeader: `Masterpieces featuring you.`,
  subHeader: `Upload your photo. Choose your style. Prints from $21.`,
  subGreeting: `Choose your favorite artists & styles. Get free digital results within 24 hours. Prints from $21.`,
  goToCreate: `Get Started (Free Trial)`,
  goToGallery: `Shop Gallery`,
  browseFullGallery: `Browse Full Gallery`,
  commissionProcess: 'Commission Process',
  pricing: 'Products & Pricing',
  gallery: 'Gallery',
  galleryNote: 'All of these pieces are made by us, with love. :)',
  stepper: {
    presentation: 'Choose Display'
  },
  petSamples: {
    title: "Pets",
    commissionButton: "Create art from your pet photos"
  },
  peopleSamples: {
    title: "People",
    commissionButton: "Create art of your loved ones"
  },
  gallerySamples: {
    title: "Artmix Originals",
    commissionButton: "Upload your own photo",
    description: "Shop premade artwork with Artmix Originals"
  },
  how: {
    title: `How does ${APP_NAME} work?`,
    description: `${APP_NAME} uses a machine learning algorithm that has studied thousands of paintings to determine how your photo might look if it were painted. Our algorithm and experts transform your photo into differently styled paintings so you can choose the perfect fit.`
  },
  tagline: `Choose your style. Get free results. Prints from $21`
}

interface LandingProps {
  children?: any;
  classes: any;
}

interface IFirstImpression {
  classes: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  imgHolder: {
    position: 'relative',
    display: 'inline-block'
  },
  action: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translate(0, -50%)',
    textAlign: 'center',
    color: 'white',
    textShadow: '0 0 15px'
  },
  greetingHolder: {
    paddingTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative'
  },
  originalPhoto: {
    position:'absolute',
    top: '5px',
    left: '5px',
    width: '150px',
    height: 'auto'
  },
  landingSectionHolder: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  landingSection: {
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '100%',
  },
  gallery: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  landingSectionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    alignItems: 'center', 
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  mainButton: {
    margin: '3px',
    padding: '12px'
  },
  hiddenLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  uploadIcon: {
    margin: "8px"
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: '100%'
  },
  greeting: {
    display: "inline-flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    margin: "20px"
  },
  sectionBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "100%",
    marginTop: '24px'
  },
  backgroundImage: {
    position: "relative",
    maxWidth: '100%'
  },
  actionButtons: {
    background:'rgba(0,0,0,0.2)',
    border: 'white 4px solid'
  },
  actionButtonContainer : {
    position: 'absolute',
    bottom: 12
  },
  logo: {
    maxWidth: '95%',
    width: '200px',
    marginBottom: '24px'
  }
}));

interface ICommissionLinkProps {
  children?: any;
}

/**
 * 
 * @param param0 
 */
const CommissionLink = ({children}: ICommissionLinkProps) => {
  const href = url({
    type: LinkType.Commission
  });

  return(<Link
    href={href}
  >
    {children}
  </Link>)
}

/**
 * The first impression that a user will get upon seeing the site. Must guide the user to the gallery or commission,
 * or allow them to continue scrolling down.
 */
const FirstImpression = ( { classes }: IFirstImpression ) => (
  <section className={classes.sectonBody}>
    <div className={classes.greetingHolder}>
      <div className={classes.greeting}>
        <img className={classes.logo} src="/static/img/artmix_text_logo.png" />
        <Typography variant="h2" style={{textAlign: 'center'}}>{_Labels.mainHeader}</Typography>
        <Typography variant="body1" style={{textAlign: 'center', margin: '14px'}}>{_Labels.subHeader}</Typography>
      </div>
    </div>
  </section>
);

// /**
//  * 
//  */
// const _RotationDemo = () => (
//   <section>
//     <Rotation
//       //before={`https://d3ly3r6f7gvy3e.cloudfront.net/master/projects/c2bec9a0-60ca-4234-8faf-17da314031b1/content/0e7d009f-d628-4adc-8851-0cacce584e20.jpg`}
//       before={`${PUBLIC_ASSETS_CDN}demo/palace/palace.jpeg`}
//       //after={`https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`}
//       afters={_afters2}
//       //after={`https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`}
//       afterSide={'right'} />
//   </section>
// )

// PEOPLE
const _afters1 = [
  {
    idx: 0,
    name: `Deep Blue Palette Knife`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/bb3a944c-ac94-4f7a-8ed2-d8463b4310e2/result.jpg`,
    full: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/bb3a944c-ac94-4f7a-8ed2-d8463b4310e2/result.jpg`,
  },
  {
    idx: 1,
    name: `Petrichor`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
    full: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
  },
  {
    idx: 2,
    name: `Van Gogh's Daylight`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
    full: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
  }
]

// PLACES
const _afters2 = [
  {
    idx: 0,
    name: `Garden`,
    thumbnail: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
  },
  {
    idx: 1,
    name: `Gum Road`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-1fb96a74-bb11-4581-9eec-e156e94785bb-1-full.jpg`,
  },
  {
    idx: 2,
    name: `Clay`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-23a1cf58-bd35-473c-831d-3bd43b9634e0-1-full.jpg`,
  },
  {
    idx: 3,
    name: `Van Gogh's Fields`,
    thumbnail: ``,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-268b8e65-8d18-43a6-8c0e-5003b4297bbe-1-full.jpg`,
  },
  {
    idx: 4,
    name: `Middle`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-2df6a522-b918-410e-ad69-ced59523eeb8-1-full.jpg`,
  },
  {
    idx: 5,
    name: `River`,
    thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-30029441-5ef1-4421-95d1-003899f5a08e-1-full.jpg`,
  },
  {
    idx: 6,
    name: `Bright Stippling`,
    thumbnail: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
    full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-43118d0a-b077-4ff8-a9c8-7093f6cb03de-1-full.jpg`,
  }
]

// PETS
const _afters3 = [
  [
    {
      idx: 0,
      name: `Garden`,
      thumbnail: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
    },
    {
      idx: 1,
      name: `Gum Road`,
      thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-1fb96a74-bb11-4581-9eec-e156e94785bb-1-full.jpg`,
    },
    {
      idx: 2,
      name: `Clay`,
      thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-23a1cf58-bd35-473c-831d-3bd43b9634e0-1-full.jpg`,
    },
    {
      idx: 3,
      name: `Van Gogh's Fields`,
      thumbnail: ``,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-268b8e65-8d18-43a6-8c0e-5003b4297bbe-1-full.jpg`,
    },
    {
      idx: 4,
      name: `Middle`,
      thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/50cda54a-f052-4178-97e0-935361cf9e85/result.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-2df6a522-b918-410e-ad69-ced59523eeb8-1-full.jpg`,
    },
    {
      idx: 5,
      name: `River`,
      thumbnail: `https://d3ly3r6f7gvy3e.cloudfront.net/master/style-transfers/492511e9-35b8-41db-8b1d-a72f19982774/result.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-30029441-5ef1-4421-95d1-003899f5a08e-1-full.jpg`,
    },
    {
      idx: 6,
      name: `Bright Stippling`,
      thumbnail: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-08f6e4fb-23cf-469b-9f87-1af10004c95c-1-full-1.jpg`,
      full: `${PUBLIC_ASSETS_CDN}demo/palace/90269897-43118d0a-b077-4ff8-a9c8-7093f6cb03de-1-full.jpg`,
    }
  ]
]

/**
 * Landing
 */
const Landing = () => {
  const classes = useStyles({});
  const [ showDeferredImages, setShowDeferredImages ] = useState(false);

  const onVisible = (isVisible: boolean) => {
    if(isVisible) {
      setShowDeferredImages(true);
    }
  }

  useEffect(() => {
    ReactGA.pageview('landing');
  }, []);

  return (
    <div className={classes.body}>
      <Head>
        <title>{'Artmix'}</title>
        <meta name="title" content='Artmix' />
      </Head>
      <FirstImpression classes={classes} />
      {/* <_RotationDemo /> */}
      <section>
        <Bullets />
        <VisibilitySensor active={!showDeferredImages} offset={{top: 100}} onChange={onVisible}><div>.</div></VisibilitySensor>
      </section>
      { 
        showDeferredImages && (
          <section className={classes.gallery}>
            <Typography variant="h1">{_Labels.gallerySamples.title}</Typography>
            <Typography style={{margin:'4px'}} variant="h4">{_Labels.gallerySamples.description}</Typography>
            <Link href={url({type: LinkType.Gallery})}>
              <Button
                style={{margin: '12px'}} 
                variant='contained'
                color='secondary'
              >{_Labels.browseFullGallery}</Button>
            </Link>
            <SampleCollection handle="frontpage" />
            <Link href={url({type: LinkType.Gallery})}>
              <Button
                variant='contained'
                color='secondary'
              >{_Labels.browseFullGallery}</Button>
            </Link>
            <div className={classes.landingSectionHolder}>
              <section className={classes.landingSection}>
                <Typography className={classes.landingSectionTitle} variant="h4">{_Labels.pricing}</Typography>
                <ProductTypes />
              </section>
            </div>
          </section>
        )
      }
    </div>
  );
}

export default Landing;
'use strict'

import React from "react";
import { Typography } from "@material-ui/core";
import PaletteTwoTone from '@material-ui/icons/PaletteTwoTone';
import SentimentSatisfiedTwoTone from '@material-ui/icons/SentimentSatisfiedTwoTone';
import LightningBolt from '@material-ui/icons/OfflineBoltTwoTone';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  bullets: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '18px',
    marginBottom: '18px'
  },
  item: {
    height: '150px',
    width: '150px',
    margin: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center-horizontal',
    justifyContent: 'center-horizontal',
    textAlign: 'center'
  },
  title: {
    alignItems: 'center',
    textAlign: 'center'
  }
}));

const _Labels = {
  left: `Limitless styles, art created exactly for you.`,
  center: `Free digital proofs within 24 hours`,
  right: `Forget spending $1,200 on custom art, prices start at $21`,
  title: `Affordable custom artwork created from your photos`
}

function Bullets(){
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        {_Labels.title}
      </Typography>
      <div className={classes.bullets}>
        <div className={classes.item}>
          <Typography variant="h4">
            <PaletteTwoTone fontSize="large" color="secondary"/>
          </Typography>
          <Typography variant="subtitle1">
            {_Labels.left}
          </Typography>
        </div>
        <div className={classes.item}>
          <Typography variant="h4">
            <LightningBolt fontSize="large" color="secondary"/>
          </Typography>
          <Typography variant="subtitle1">
            {_Labels.center}
          </Typography>
        </div>
        <div className={classes.item}>
          <Typography variant="h4">
            <SentimentSatisfiedTwoTone fontSize="large" color="secondary"/>
          </Typography>
          <Typography variant="subtitle1">
            {_Labels.right}
          </Typography>
        </div>
      </div>
    </div>
  )
};

export default Bullets;